import React, { ReactChild } from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { rem } from 'ui/helpers';

import Button from 'ui/components/atoms/button';
import ChevronLeft from 'ui/components/atoms/icons/chevron-left';
import Logo from 'ui/components/atoms/logo';

type Props = {
  className?: string,
  countdown?: ReactChild,
  video?: ReactChild,
  notification?: ReactChild,
};

const Wrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.black};
  display: flex;
  flex-direction: row;
  height: 100vh;
  position: relative;
`;

const VideoWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 0;
`;

const Column = styled.div`
  height: 100%;
  width: 100%;
  overflow: hidden;
  padding: ${({ theme }) => theme.spacing.xs} 0.4rem;
  position: relative;
`;

const LogoContainer = styled.div`
  background-color: transparent;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
`;

const CountdownContainer = styled.div`
  position: absolute;
  right: 1em;
  bottom: 1em;
  z-index: 1;
`;

const NotificationContainer = styled.div`
  position: absolute;
  right: 1em;
  top: 1em;
  z-index: 1;
`;

const BackButton = styled(Button)`
  position: absolute;
  left: ${rem(50)};
  bottom: ${rem(50)};
  z-index: 1;
`;

const Upcoming = ({ className = '', countdown, video, notification }: Props) => {
  const history = useHistory();
  return (
    <Wrapper>
      {
        video && <VideoWrapper>{ video }</VideoWrapper>
      }
      <Column
        className={className}
      >
        {
          !video &&
          (
            <LogoContainer>
              <Logo color="white" />
            </LogoContainer>
          )
        }
        { countdown && (
          <CountdownContainer>
            { countdown }
          </CountdownContainer>
        )}
        { notification && (
          <NotificationContainer>
            { notification }
          </NotificationContainer>
        )}
        <BackButton
          onClick={() => history.goBack()}
          labelPosition="right"
          icon={<ChevronLeft />}
          iconOnly
        />
      </Column>
    </Wrapper>
  );
};

export default Upcoming;
