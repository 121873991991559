import {
  UpcomingLessonFragmentFragment,
} from 'app/in-studio/types/graphql';

import styled from 'styled-components';
import Typography from 'ui/components/atoms/typography';
import { rem } from 'ui/helpers';

import { trainerNames } from 'ui/components/utils/trainer-names';

import React from 'react';
import ClassLength from 'ui/components/atoms/class-length';

type LessonInstanceNotificationProps = {
  lesson: UpcomingLessonFragmentFragment;
};

const LessonInstanceNotification = ({ lesson }: LessonInstanceNotificationProps) => {
  const SplitBox = styled.div`
    display: flex;
    background-color: ${({ theme }) => theme.colors.blackOpaque};
    border-radius: ${rem(8)};
    overflow: hidden;
    padding: ${({ theme }) => theme.spacing.m};
    max-width: ${rem(600)};
  `;

  const InfoBox = styled.div`
    display: flex;
    flex-direction: column;
    padding-left: ${({ theme }) => theme.spacing.m};
  `;

  const Image = styled.div<{ url?: string}>`
    height: ${rem(182)};
    width: ${rem(182)};
    background-image: url('${({ url }) => url}');
    background-size: cover;
    background-position: center;
    border-radius: ${rem(2)};
  `;

  const UnPad = styled.div`
    margin: -${({ theme }) => theme.spacing.xs};
    padding-top: ${rem(8)};
  `;

  return (
    <SplitBox>
      <div>
        <Image url={lesson.mainImage?.url} />
      </div>
      <InfoBox>
        <Typography variant="double-pica">{lesson.name}</Typography>
        <Typography weight="regular" lineHeight="l">with {trainerNames(lesson.trainers)}</Typography>
        <UnPad>
          <ClassLength duration={lesson.durationRange} showIcon />
        </UnPad>
      </InfoBox>
    </SplitBox>
  );
};

export default LessonInstanceNotification;
