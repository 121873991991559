import React, { useEffect, useCallback, useMemo } from 'react';
import ReactDOM from 'react-dom';
import styled from 'styled-components';
import { Switch, BrowserRouter, Route } from 'react-router-dom';
import { ApolloProvider } from '@apollo/react-hooks';
import { ThemeProvider, GlobalStyles } from 'ui';

import { LoginRoute, ProtectedRoute } from 'app/in-studio/utils/auth-routes';
import ErrorBoundary from 'ui/components/utils/error-boundary';
import createApolloClient from 'utils/apollo-client';

import useServices, { ServicesProvider } from 'services';

import { StateProvider, useDispatch, useAppState } from 'state';
import useConfig, { ConfigProvider } from 'app/in-studio/config-provider';
import hideInitialLoadLogo from 'utils/hide-initial-load-logo';
import { refreshTokens, logout } from 'actions/auth';
import AnimatedLoader from 'ui/components/atoms/animated-loader';
import useLogger from 'app/hooks/use-logger';
import { RouteContext } from 'utils/use-routes';
import routes from 'app/in-studio/routes';
import useInstallation from 'utils/use-installation';
import FourOhFour from 'app/pages/404';
import PageContainer from 'app/in-studio/organisms/page-container';

import DebugBar from 'app/in-studio/debug';

import * as Sentry from '@sentry/browser';

const Flexbox = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
`;

const AuthPendingSplashWrapper = styled.div`
  background: ${({ theme }) => theme.colors.black};
  height: 100vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

const StyledAnimatedLoader = styled(AnimatedLoader)`
  height: 5rem;
  width: 5rem;
`;

const AuthPendingSplash = () => (
  <AuthPendingSplashWrapper>
    <StyledAnimatedLoader />
  </AuthPendingSplashWrapper>
);

const Pages = () => {
  const dispatch = useDispatch();
  const { config } = useConfig();
  const services = useServices();
  const logger = useLogger('in-studio:index');

  // We explicitly set these to prevent redundant updating and rerendering
  const refreshToken = useAppState((state) => state.auth.refreshToken);
  const authInitialized = useAppState((state) => state.auth.initialized);

  const virtualStudioId = useAppState((state) => state.virtualStudio.id);

  const onTokenRefresh = useCallback((payload) => dispatch(refreshTokens(payload)), [dispatch]);
  const onTokenRefreshError = useCallback(() => dispatch(logout()), [dispatch]);

  const { client } = useMemo(() => createApolloClient({
    uri: config.WORKOUT_API_URL,
    authService: services.auth,
    clientId: config.AUTH_CLIENT_ID,
    version: config.VERSION,
    debug: config.CONNECT_APOLLO_DEV_TOOLS,
    subscriptionUri: config.SUBSCRIPTION_API_URL,
    subscriptionsMinimumTokenTTLSeconds: config.SUBSCRIPTIONS_MINIMUM_TOKEN_TTL_SECONDS,
    contentPackagePermissionsEnabled: config.CONTENT_PACKAGE_PERMISSIONS_ENABLED,
    virtualStudioId,
    onTokenRefresh,
    onTokenRefreshError,
  }), [config, services.auth, virtualStudioId, onTokenRefresh, onTokenRefreshError]);

  useEffect(() => {
    if (authInitialized || !refreshToken) {
      hideInitialLoadLogo(config.INITIAL_LOAD_LOGO_ID, config.INITIAL_LOAD_TRANSITION_SECONDS);
      return;
    }

    // TODO is there a pattern that means we don't have this crap everywhere...
    services.auth.refreshToken({ refreshToken })
      .then((response) => {
        logger.debug('refreshed tokens for initial page load');
        onTokenRefresh(response);
      })
      .catch(() => {
        logger.debug('failed to refresh tokens on initial page load - discard them and proceed to "login" route');
        onTokenRefreshError();
      });
  }, [
    authInitialized,
    services.auth,
    onTokenRefresh,
    onTokenRefreshError,
    refreshToken,
    config.INITIAL_LOAD_LOGO_ID,
    config.INITIAL_LOAD_TRANSITION_SECONDS,
    logger,
  ]);

  useInstallation({ client, platform: config.APP_TYPE });

  if (!authInitialized) {
    return <AuthPendingSplash />;
  }

  return (
    <ApolloProvider client={client}>
      <RouteContext.Provider value={{ routes }}>
        <BrowserRouter>
          <Flexbox>
            <PageContainer>
              <Switch>
                {
                  Object.values(routes).map(({
                    acceptedPaths,
                    component: Component,
                    exact,
                    unauthenticated = [false],
                    requiredPermissions,
                  }, i: number) => {
                    const RouteType = unauthenticated.includes(true) ? LoginRoute : ProtectedRoute;

                    return (
                      <RouteType
                        key={`${acceptedPaths[0]}.${i * 2}`}
                        component={Component}
                        exact={exact}
                        path={acceptedPaths}
                        requiredPermissions={requiredPermissions}
                      />
                    );
                  })
                }
                <ProtectedRoute component={FourOhFour} />
              </Switch>
            </PageContainer>
            {config.ALLOW_IN_SESSION_DEBUG && (
              <Route
                path={['/lesson-instance/:lessonInstanceId', '/']}
                render={({ match: { params } }) => {
                  const lessonInstanceId = params.lessonInstanceId || null;
                  return lessonInstanceId ? <DebugBar lessonInstanceId={lessonInstanceId} /> : null;
                }}
              />
            )}
          </Flexbox>
        </BrowserRouter>
      </RouteContext.Provider>
    </ApolloProvider>
  );
};

const AppWithConfig = () => {
  const { config } = useConfig();

  useEffect(() => {
    Sentry.init({
      dsn: config.SENTRY_DSN,
      environment: config.SENTRY_ENVIRONMENT,
    });
  }, [config.SENTRY_DSN, config.SENTRY_ENVIRONMENT]);

  // TODO - check if we don't need to do the colors
  return (
    <ErrorBoundary>
      <ServicesProvider config={config}>
        <ThemeProvider>
          <>
            <GlobalStyles />
            <StateProvider>
              <Pages />
            </StateProvider>
          </>
        </ThemeProvider>
      </ServicesProvider>
    </ErrorBoundary>

  );
};

const App = () => (
  <ConfigProvider>
    <AppWithConfig />
  </ConfigProvider>
);

ReactDOM.render(<App />, document.getElementById('root'));
